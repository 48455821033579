import { useModal } from "vue-final-modal";
import ClassroomsLicenseReviewModal from "~/components/Classrooms/LicenseReview/Modal.vue";

export const useShowClassroomsLicenseReviewModal = () => {
  const { open, close } = useModal({
    component: ClassroomsLicenseReviewModal,
    attrs: {
      onClose: () => {
        close();
      },
    },
  });

  open();
};
