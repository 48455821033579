<script lang="ts" setup>
import { Checklists, type Checklist } from "types/Checklist";
import AssignmentChecklistModal from "../Assignment/ChecklistModal.client.vue";
import { useModal } from "vue-final-modal";
import { type Article } from "types/Article";
import ArticleModal from "../Article/Modal.vue";
import { type Assignments } from "classes/models/assignments/assignment.model";
import { AssignmentsService } from "classes/models/assignments/assignments.service";
import { GuidedTourEventName } from "~/types/enums/GuidedTourEventNames.enum";
import { Subscription } from "rxjs";
import { useShowClassroomsLicenseReviewModal } from "~/composables/useShowClassroomsLicenseReviewModal";

const selectedTab = ref<"ungraded" | "graded" | "summary">("ungraded");

const selectTab = (tab: "ungraded" | "graded" | "summary") => {
  selectedTab.value = tab;
};

const levelFilter = ref<string | undefined>(undefined);
const lessonFilter = ref<string | undefined>(undefined);

const userDocumentsStore = useUserDocumentsStore();
const { allUngradedDocuments: ungradedDocuments, numGradedDocuments } =
  storeToRefs(userDocumentsStore);
const gradedDocuments = ref<SubmittedDocuments>([]);

const filteredUngradedDocuments = computed(() => {
  const updatedDocs = ungradedDocuments.value.filter((document) => {
    const hasMatchingLevel = levelFilter.value
      ? document.levelId == levelFilter.value
      : true;

    const hasMatchingLesson = lessonFilter.value
      ? document.lessonId == lessonFilter.value
      : true;

    return hasMatchingLevel && hasMatchingLesson;
  });

  return [...updatedDocs];
});

const filteredGradedDocuments = computed(() => {
  const updateDocs = gradedDocuments.value.filter((document) => {
    const hasMatchingLevel = levelFilter.value
      ? document.levelId == levelFilter.value
      : true;

    const hasMatchingLesson = lessonFilter.value
      ? document.lessonId == lessonFilter.value
      : true;

    return hasMatchingLevel && hasMatchingLesson;
  });

  return [...updateDocs];
});

const checklists = ref<Checklist[]>([]);
const isShowingModal = ref(false);

const activeAssignments = ref<Assignments>([]);

// Home page tour
const tourId = "iIqLAx35lYCvRVqe81cH";

// Advanced Tour
const advancedTourId = "0GLBSzr79wveL8Zxbeu2";

const userChecklistsSubscription = ref<Subscription | undefined>();
const assignmentSubscription = ref<Subscription | undefined>();

onMounted(() => {
  userChecklistsSubscription.value = useUserChecklists()
    .streamUserChecklists()
    .subscribe((newChecklists: Checklists) => {
      checklists.value = newChecklists;
    });

  const currentUid = useCurrentUID();

  checkClassroomLicenses();

  if (currentUid) {
    assignmentSubscription.value =
      AssignmentsService.streamAllActiveAssignments(currentUid).subscribe(
        (assignments) => {
          activeAssignments.value = assignments;
        }
      );
  }

  // If the main tour has not be completed then we're going to show that instead of checking for a tip to show
  if (isTourComplete.value(tourId) != true) return;

  checkUserTips();
});

onUnmounted(() => {
  userChecklistsSubscription.value?.unsubscribe();
  assignmentSubscription.value?.unsubscribe();
});

const guidedTourStore = useGuidedTours();
const { allTours } = storeToRefs(guidedTourStore);

const appUserStore = useAppUserData();
const { isTourComplete, appUser, shouldHideSubmissionNotice, isInitialized } =
  storeToRefs(appUserStore);

const tourEventSubscription = ref<Subscription | undefined>();

watch([allTours, gradedDocuments], () => {
  checkShowTutorials();

  if (
    isTourComplete.value(tourId) != true &&
    useGuidedTours().hasActiveTour != true
  ) {
    useGuidedTours().setTourToShow(tourId);
  }

  // So what we want to do here is if the user has not compelted the advanced tour and htey have completed more than 5 documents then show the next one
  // This tour is not yet ready yet.
  // TODO: Uncomment out this block of code one Heidi has approved the advanced tour.
  // if (
  //   isTourComplete.value(advancedTourId) != true &&
  //   gradedDocuments.value.length > 5 &&
  //   useGuidedTours().hasActiveTour != true
  // ) {
  //   useGuidedTours().setTourToShow(advancedTourId);
  // }

  if (tourEventSubscription.value) {
    tourEventSubscription.value.unsubscribe();
  }

  tourEventSubscription.value = useTourEventBus().events.subscribe(
    async (event) => {
      if (!event) return;

      if (
        event.name == GuidedTourEventName.tourComplete &&
        event.data != undefined
      ) {
        await appUserStore.markTourAsComplete(event.data as string);
      }
    }
  );
});

const userClassroomsData = useUserClassroomsDataStore();
const {
  hasAssignmentsWithoutChecklists,
  activeClassrooms,
  allAssignments,
  allStudents,
} = storeToRefs(userClassroomsData);

watch(hasAssignmentsWithoutChecklists, () => {
  // Ok let's open up a modal.
  if (
    hasAssignmentsWithoutChecklists.value == true &&
    isShowingModal.value != true
  ) {
    isShowingModal.value = true;

    const { open, close } = useModal({
      component: AssignmentChecklistModal,
      attrs: {
        checklists: checklists.value,
        onClose: () => {
          isShowingModal.value = false;
          close();
        },
      },
    });

    open();
  }
});

const activeArticle = ref<Article | undefined>(undefined);
const checkUserTips = async () => {
  if (isShowingModal.value == true) return;

  const article = await useUserTips().getUserTip();

  if (article == undefined) return;

  isShowingModal.value = true;
  activeArticle.value = article;

  const { open, close } = useModal({
    component: ArticleModal,
    attrs: {
      article: article,
      onClose: () => {
        isShowingModal.value = false;
        close();
      },
    },
  });

  open();
};

const showFilters = ref(false);
const toggleShowFilters = () => {
  showFilters.value = !showFilters.value;
};

const route = useRoute();

const checkShowTutorials = () => {
  if (useGuidedTours().hasActiveTour == true) return;

  const queryParams = route.query;

  if ((queryParams.showTutorial as any) != "true") return;

  if ((queryParams.tutorialId as any) == undefined) return;

  const tutorialId = queryParams.tutorialId as string;

  const tour = allTours.value.find((tour) => tour.id == tutorialId);

  if (tour == undefined) return;

  useGuidedTours().startTour(tour);
  navigateTo("/");
};

// Maybe I'll move this to the middlware or to it's own middleware. That's not a bad idea.
const capabilityService = useCapabilitiesServiceStore();
const { hasEnoughClassroomLicenses } = storeToRefs(capabilityService);

watch(hasEnoughClassroomLicenses, () => {
  checkClassroomLicenses();
});

const checkClassroomLicenses = () => {
  if (hasEnoughClassroomLicenses.value == true) return;

  useShowClassroomsLicenseReviewModal();
};
</script>

<template>
  <div
    class="p-4 h-full flex flex-col overflow-hidden"
    data-step-id="documents-dashboard-container"
  >
    <div
      class="flex flex-row items-center mb-2 shrink-0 w-full justify-between"
    >
      <div class="flex flex-row items-center">
        <h1 class="text-h4 mr-2">Gradebook</h1>
        <ClientOnly>
          <span v-if="checklists" class="mx-2"> - </span>
        </ClientOnly>
        <UploadStudentSubmissionButton
          v-if="checklists"
          data-step-id="student-upload-button"
          :classrooms="activeClassrooms"
          :assignments="allAssignments"
          :students="allStudents"
          :checklists="checklists"
          :show-samples="true"
          :class="{
            ' border rounded border-primary-default border-dashed':
              shouldHideSubmissionNotice == false,
          }"
        />
        <div
          v-if="isInitialized && shouldHideSubmissionNotice == false"
          class="border rounded border-dashed border-warning-default bg-opacity-10 bg-warning-default p-2 ml-4 flex flex-row items-center"
        >
          <icon name="bi:arrow-left" size="20" class="mr-2 mt-[1px]" />
          <span>
            Click to access sample papers or upload your student submissions.
          </span>
          <BaseTextButton
            class="ml-2"
            @click="appUserStore.hideSubmissionNotice()"
          >
            Hide
          </BaseTextButton>
        </div>
      </div>
    </div>
    <div class="flex flex-row items-start grow min-h-0">
      <DocumentsClassroomsSideBar />

      <div class="flex flex-col w-full h-full px-4">
        <div class="flex flex-row items-center mt-4 mb-2">
          <h2 class="text-h4">Filters</h2>
          <BaseIconButton
            class="ml-2"
            :tooltip="showFilters ? 'Hide Filters' : 'Show Filters'"
            @click="toggleShowFilters"
          >
            <icon
              :name="
                showFilters
                  ? 'material-symbols:filter-alt-off-sharp'
                  : 'material-symbols:filter-alt-sharp'
              "
            />
          </BaseIconButton>
        </div>
        <TransitionsCollapsible>
          <div v-if="showFilters" class="flex flex-row items-center mb-4">
            <div class="mr-4">
              <LevelsSelect v-model="levelFilter" class="!w-[250px]" />
            </div>
            <div>
              <LessonsSelect v-model="lessonFilter" class="!w-[250px]" />
            </div>
          </div>
        </TransitionsCollapsible>
        <DocumentsSummary />
        <div
          class="shrink-0 flex flex-row rounded border-2 bg-surface"
          data-step-id="documents-tab-controller"
        >
          <span
            class="grow text-center border-r-2 cursor-pointer hover:bg-light-default p-2"
            :class="{
              'bg-light-shade': selectedTab == 'ungraded',
            }"
            data-step-id="documents-ungraded-tab"
            @click="selectTab('ungraded')"
          >
            Ungraded
            <span> ({{ filteredUngradedDocuments.length }}) </span>
          </span>
          <span
            class="grow text-center cursor-pointer hover:bg-light-default p-2"
            :class="{
              'bg-light-shade': selectedTab == 'graded',
              'border-r-2 ': activeAssignments.length > 0,
            }"
            @click="selectTab('graded')"
          >
            Graded
            <span> ({{ numGradedDocuments }}) </span>
          </span>
        </div>
        <div
          class="grow min-h-0 overflow-y-auto flex flex-col"
          data-step-id="document-lists-container"
        >
          <DocumentsList
            v-if="selectedTab == 'ungraded'"
            :key="filteredUngradedDocuments.length"
            :documents="filteredUngradedDocuments"
            class="overflow-y-auto"
            align="left"
          />
          <!-- <DocumentsList
            v-if="selectedTab == 'graded'"
            :documents="filteredGradedDocuments"
            :key="filteredGradedDocuments.length"
            align="right"
            class="overflow-y-auto"
          /> -->
          <DocumentsInfiniteList
            v-if="selectedTab == 'graded'"
            :level-id="levelFilter"
            :lesson-id="lessonFilter"
            :states="[DocumentState.graded]"
            align="right"
            class="overflow-y-auto"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
