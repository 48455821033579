<script lang="ts" setup>
const userClassroomDataStore = useUserClassroomsDataStore();
const { visibleClassrooms } = storeToRefs(userClassroomDataStore);
</script>

<template>
  <div class="flex flex-col w-full" v-if="visibleClassrooms">
    <ClassroomFoldersListItem
      v-for="classroom of visibleClassrooms"
      :key="classroom.id"
      :classroom="classroom"
      class="mb-2"
    />
  </div>
</template>

<style scoped></style>
