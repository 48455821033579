<script lang="ts" setup>
import { DemosService } from "classes/models/demos/demos-service";
import { signOut } from "firebase/auth";

definePageMeta({
  middleware: ["auth", "app"],
});

const isLoggingOut = ref(false);
const logout = async () => {
  isLoggingOut.value = true;
  await useAppUserData().logout();
  isLoggingOut.value = false;
};

const tourBuilderStore = useTourBuilder();
const { isBuildingTour } = storeToRefs(tourBuilderStore);

const appUserStore = useAppUserData();
const { isUserSystemAdmin, isDemoAccount } = storeToRefs(appUserStore);
</script>

<template>
  <div class="overflow-hidden">
    <BaseAppBar class="fixed top-0 left-0 z-[888]">
      <template #leading>
        <div class="h-full p-2 flex fled-row items-center">
          <img src="/img/logo.png" class="h-full" />
        </div>
      </template>
      <template #actions>
        <ClientOnly>
          <HelpButton class="mr-4" />
          <ReportProblemButton class="mr-4" :is-consolidated="true" />
          <BaseTextButton
            v-tooltip="'Logout'"
            color="danger"
            @click="logout"
            class="mr-4"
            :show-spinner="isLoggingOut"
          >
            <icon name="material-symbols:logout" size="25" />
          </BaseTextButton>
          <NuxtLink to="/settings" class="mr-4">
            <BaseTextButton
              data-step-id="settings-nav-button"
              v-tooltip="'Settings'"
            >
              <div class="flex flex-row items-center">
                <icon name="ic:baseline-settings" size="25" />
              </div>
            </BaseTextButton>
          </NuxtLink>
          <span class="flex flex-row items-center">
            <VDropdown v-if="isUserSystemAdmin || isDemoAccount">
              <BaseTextButton>
                <icon name="material-symbols:more-vert" size="25" />
              </BaseTextButton>
              <template #popper>
                <div
                  v-if="isUserSystemAdmin"
                  class="flex flex-row items-center p-4"
                >
                  <BaseToggle v-model="isBuildingTour" />
                  <span class="ml-2"> Enable Tour Builder </span>
                </div>
                <ResetDemoButton v-if="isDemoAccount" class="w-full" />
                <CreateDemoButton v-if="isUserSystemAdmin" class="w-full" />
              </template>
            </VDropdown>
          </span>
        </ClientOnly>
      </template>
    </BaseAppBar>

    <div class="pt-[60px] h-screen overflow-hidden">
      <DocumentsContainer />
    </div>
  </div>
</template>

<style></style>
