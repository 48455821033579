<script lang="ts" setup>
import { VueFinalModal } from "vue-final-modal";

const emit = defineEmits(["close"]);

const userClassroomsData = useUserClassroomsDataStore();
const { classrooms } = storeToRefs(userClassroomsData);

const capabilityService = useCapabilitiesServiceStore();
const { numActiveClassrooms, numAllowedClassrooms } =
  storeToRefs(capabilityService);

const numClassroomsToArchive = computed(() => {
  return numActiveClassrooms.value - numAllowedClassrooms.value;
});

const purchaseClassrooms = () => {
  useBaseAlert("Oops!", "Coming soon.");
};
</script>

<template>
  <VueFinalModal
    class="dialog-modal"
    content-class="alert-modal-content bg-surface rounded overflow-hidden shadow absolute h-[80%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
  >
    <NuxtLayout name="dialog" @close="$emit('close')">
      <div class="w-[600px] h-[80%] p-4 overflow-auto">
        <div v-if="numClassroomsToArchive > 0">
          <p>
            Oops! You do not have enough classroom licenses. You have
            {{ numActiveClassrooms }} active classrooms but are licensed for
            {{ numAllowedClassrooms }}. Please archive
            {{ numClassroomsToArchive }}
            {{ numClassroomsToArchive > 1 ? "classrooms" : "classroom" }} or
            purchase additional licenses.
          </p>
          <BaseButton @click="purchaseClassrooms" class="mt-4">
            Purchase More Classrooms
          </BaseButton>
        </div>
        <div v-if="numClassroomsToArchive <= 0">
          <p>You now have enough classroom licenses.</p>
        </div>
        <div class="mt-4">
          <ClassroomsLicenseReviewItem
            v-for="classroom in classrooms"
            :classroom="classroom"
            class="border-b last:border-none py-2"
          />
        </div>
      </div>
    </NuxtLayout>
  </VueFinalModal>
</template>

<style scoped></style>
