<script lang="ts" setup>
import { DemosService } from "classes/models/demos/demos-service";

const userClassroomsData = useUserClassroomsDataStore();
const { classrooms, allAssignments, allStudents } =
  storeToRefs(userClassroomsData);

const isCreatingDemo = ref(false);
const createDemo = async () => {
  isCreatingDemo.value = true;

  const allDocuments = await DemosService.fetchAllUserDocuments(
    useCurrentUID()!
  );

  const allCriteriaTransactions =
    await DemosService.fetchAllCriteriaTransactions(useCurrentUID()!);

  await DemosService.createDemo(
    classrooms.value,
    allAssignments.value,
    allStudents.value,
    allDocuments,
    allCriteriaTransactions
  );
  isCreatingDemo.value = false;
};
</script>

<template>
  <BaseTextButton
    class="mr-2"
    @click="createDemo"
    :show-spinner="isCreatingDemo"
  >
    Create Demo
  </BaseTextButton>
</template>

<style scoped></style>
