<script lang="ts" setup>
const appUserDataStore = useAppUserData();
const { hasClassroomMode, hasLMSConfig } = storeToRefs(appUserDataStore);

const showClassroomSideBar = computed(() => {
  return hasClassroomMode.value == true || hasLMSConfig.value == true;
});
</script>

<template>
  <TransitionExpandable>
    <div
      class="flex flex-col mb-4 items-center !w-[320px] h-full border-r-2 pr-4"
      v-if="showClassroomSideBar"
      data-step-id="classroom-sidebar"
    >
      <div
        class="flex flex-row items-center border-b w-full justify-between mb-2 py-2"
      >
        <label class="text-h6"> Classrooms </label>
        <VDropdown>
          <BaseIconButton :size="35" data-step-id="classroom-popover-button">
            <icon name="material-symbols:more-vert" size="35" />
          </BaseIconButton>
          <template #popper>
            <div class="flex flex-col bg-surface z-50">
              <NuxtLink
                to="/configure/lms/classrooms"
                data-step-id="configure-classrooms-button"
              >
                <BaseTextButton> Add/Edit Classrooms </BaseTextButton>
              </NuxtLink>
            </div>
          </template>
        </VDropdown>
      </div>
      <div class="flex flex-col grow min-h-0 overflow-auto w-full">
        <ClassroomFoldersList v-if="hasLMSConfig" />
        <div v-if="hasLMSConfig != true">
          <p class="my-4 text-center">
            Would you like to set up your classrooms?
          </p>
          <NuxtLink to="/configure/lms/choose">
            <BaseButton> Set Up Classrooms </BaseButton>
          </NuxtLink>
        </div>
      </div>
    </div>
  </TransitionExpandable>
</template>

<style scoped></style>
